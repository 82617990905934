import React from 'react';
import {Card, CardBody, CardTitle, Table, Button, Modal, InputGroup, Nav, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Row, Col, NavItem, NavLink} from 'reactstrap';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import {storage, db, functions} from '../components/firebase/firebase';
import {observer, inject} from 'mobx-react';
import {RangeStepInput} from 'react-range-step-input';
import classnames from 'classnames';
import arrayMove from 'array-move';
import Cropper from 'react-easy-crop';
import moment from "moment";
import drinkPlaceholder from '../assets/images/drinkPlaceholder.png';
import Warning from '../assets/images/pp_warning.svg';

@inject('observableStore')
@observer

class Menu extends React.Component {	
	
	state = {
		currMenu: 0,
		
		modal: false, 
		saveModal: false,
		newMenuModal: false,
		editMenuModal: false,
		infoModal: false,
		publishModal: false,
		delModal: false,

		optieModal: false,
		productTab: 'product',
		editOptieIndex: '',
		newItemOpties: [],
		newOptieText: '',
			
		newItemImg: '',
		newItemName: '',	
		newItemInfo: '',
		newItemServe: 'Direct serveren',
		newItemPrice: 1,
		newItemBTW: 0.09,
		newItemStatus: 'beschikbaar',
		newItemCat: 'drinken',
		newItemBarArr: [],
		editItemIndex: '',
		newItemEighteen: false,
		newItemCup: false,
		
		iconList: [],
		iconsLoaded: false,
		uploadState: false,	
		
		newMenuLabel: '',
		currBar: '',
		
		imageSrc: '',
		crop: { x: 0, y: 0 },
		zoom: 1,
		reqFields: [],
		
		publishState: 0,

		searchInput: ''
	}
	
	saveCurrMenu = () => {		
		db.collection("cateraars").doc(this.props.observableStore.uid).update({[`events.${this.props.observableStore.currEvent}.menus`]:this.props.observableStore.menus});			
	}
	
	toggle = () => this.setState({modal: !this.state.modal, imageSrc: ''});
	toggleNewMenu = () => { this.setState({newMenuLabel: '', newMenuModal: !this.state.newMenuModal})}
	toggleEditMenu = () => { this.setState({newMenuLabel: this.props.observableStore.menus[this.state.currMenu].label, editMenuModal: !this.state.editMenuModal})}
	togglePublishModal = () => this.setState({publishModal: !this.state.publishModal, publishState: 0,});
	toggleInfoModal = (error) => this.setState({infoModal: !this.state.infoModal, 'infoModalTxt': error});
	toggleDelModal = () => {this.setState({delModal: !this.state.delModal})}	
	toggleOptieModal = () => {this.setState({optieModal: !this.state.optieModal})}	
	
	addMenu = () => {
		if(this.props.observableStore.menus.length === 10){
			this.toggleInfoModal('Je kunt maximaal 10 menulijsten opslaan');
		}
		else{
			this.props.observableStore.menus.push({label: this.state.newMenuLabel, items: []});
			this.setState({currMenu: this.props.observableStore.menus.length -1}, () => {this.toggleNewMenu()});
		}
		this.saveCurrMenu();
	}
	
	editMenu = () => {
		this.props.observableStore.menus[this.state.currMenu].label = this.state.newMenuLabel;
		this.toggleEditMenu();
	}
	
	delMenu = () => {	
		if(this.props.observableStore.menus.length > 1){
			this.setState({currMenu: 0}, () => {this.toggleEditMenu()});
			this.props.observableStore.menus.splice(this.state.currMenu, 1);
			this.saveCurrMenu();
		}
		else{
			this.toggleInfoModal('Je kunt dit menu niet verwijderen');
		}
	}
	
	publishMenu(){
		this.setState({publishState: 1});
		var cateraarPublish = functions.httpsCallable('cateraarPublish');
		cateraarPublish({oid: this.props.observableStore.oid, eid: this.props.observableStore.currEvent, menu: this.props.observableStore.menus[this.state.currMenu].items}).then(result => {
			if(result.data.text === 'succes'){this.setState({publishState: 2})}
			else{this.setState({publishState: 3})}
		});
	}
	
	newItem = () => {
		this.setState({editItemIndex: 'new', newItemName: '', newItemOpties: [], newItemImg: '', newItemPrice: 1, newItemBTW: 0.09, newItemStatus: 'beschikbaar'});
		if(this.state.iconList.length === 0){ this.getMenuIcons() }
		this.toggle();
	}

	editItem = (index, itemName, itemInfo, itemImg, itemPrice, itemBTW, itemServe, itemStatus, reqEighteen, opties, cup) => {
		this.setState({editItemIndex: index, newItemName: itemName, newItemOpties: opties ? opties : [], newItemInfo: itemInfo, newItemServe: itemServe, newItemEighteen: reqEighteen ? true : false, newItemCup: cup ? true : false, newItemImg: itemImg, newItemPrice: itemPrice, newItemBTW: itemBTW, newItemStatus: itemStatus});
		if(this.state.iconList.length === 0){ this.getMenuIcons() }
		this.toggle();
	}
	
	delItem = () => {
		var newItems = this.props.observableStore.menus[this.state.currMenu].items;
		newItems.splice(this.state.editItemIndex, 1);
		this.props.observableStore.menus[this.state.currMenu].items = newItems;
		this.toggle();
		this.toggleDelModal();
		this.saveCurrMenu();
	}
	
	getMenuIcons = () => {
		var iconList = [];
		var iconCount = 1;
		var refPromises = [];
		storage.ref().child('cateraars').child(this.props.observableStore.uid).listAll().then(result => {
			result.items.forEach(imageRef => {	
				imageRef.getDownloadURL().then(url => {
					iconList.push(url);				
					if(iconCount === result.items.length){
						this.setState({iconList: iconList, iconsLoaded: true});
					}
					iconCount++;
				})					
			});  
		});	
	}
	
	importMenu(){
		db.collection("events").doc(this.props.observableStore.currEvent).get().then(doc => {
			var newMenu = doc.data().menu.find(menu => menu.cateraar === this.props.observableStore.uid);
			if(newMenu){
				this.props.observableStore.menus[this.state.currMenu].items = newMenu.items;
				this.saveCurrMenu();
				this.toggleInfoModal('Menu is geimporteerd');
			}
			else{
				this.toggleInfoModal('Kan geen menu vinden voor dit account');
			}
		})
	}
	
	checkImg = () => {
		if(this.state.imageSrc !== '' && this.reqFields('item')){
			this.saveImage();
		}
		else{
			this.saveItem();
		}
	}
	
	saveItem() {	
		var menuArr = this.props.observableStore.menus[this.state.currMenu].items;	
		if(this.state.editItemIndex === 'new')
		{
			var newItem = {
				id: this.getUniqueID('item'), 
				name: this.state.newItemName, 
				info: this.state.newItemInfo,
				serve: this.state.newItemServe,
				img: this.state.newItemImg, 
				price: this.state.newItemPrice,
				btw: this.state.newItemBTW,
				status: this.state.newItemStatus,
				opties: this.state.newItemOpties
			}
			if(this.state.newItemEighteen){newItem['reqEighteen'] = true}
			if(this.state.newItemCup){newItem['cup'] = true}
			menuArr.push(newItem);
		}
		else{
			menuArr[this.state.editItemIndex].name = this.state.newItemName;
			menuArr[this.state.editItemIndex].info = this.state.newItemInfo;
			menuArr[this.state.editItemIndex].serve = this.state.newItemServe;
			menuArr[this.state.editItemIndex].img = this.state.newItemImg;
			menuArr[this.state.editItemIndex].price = this.state.newItemPrice;
			menuArr[this.state.editItemIndex].btw = this.state.newItemBTW;	
			menuArr[this.state.editItemIndex].status = this.state.newItemStatus;	
			menuArr[this.state.editItemIndex].opties = this.state.newItemOpties;
			if(this.state.newItemEighteen){menuArr[this.state.editItemIndex].reqEighteen = true}
			else if(menuArr[this.state.editItemIndex].reqEighteen){delete menuArr[this.state.editItemIndex].reqEighteen}
			if(this.state.newItemCup){menuArr[this.state.editItemIndex].cup = true}	
			else if(menuArr[this.state.editItemIndex].cup){delete menuArr[this.state.editItemIndex].cup}			
		}
		this.toggle('close');
		this.saveCurrMenu();
	}
	
	saveImage() {	 
		const canvas = document.createElement('canvas');
		var pixelCrop = this.state.pixelCrop;
		canvas.width = 330;
		canvas.height = 176;
		const ctx = canvas.getContext('2d');
		ctx.fillStyle = "white";
		ctx.fillRect(0, 0, pixelCrop.width, pixelCrop.height);
		var img = new Image();
		img.src = this.state.imageSrc;		
		img.onload = () => {
			ctx.drawImage(img, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, 330, 176);			
			canvas.toBlob(blob => {
				const filename = new Array(15).join().replace(/(.|$)/g, function(){return ((Math.random()*36)|0).toString(36)[Math.random()<.5?"toString":"toUpperCase"]()}) + '.jpeg';
				const file = new File([blob], filename, {type: blob.type});				
				this.setState({uploadState: true});
				var ImageRef = storage.ref().child('cateraars/' + this.props.observableStore.uid + '/' + file.name);
				ImageRef.getDownloadURL().then((url) => {this.setState({newItemImg: url, uploadState: false})}).catch(err => {							
					ImageRef.put(file).then(() =>{ImageRef.getDownloadURL().then((url) => {this.setState({newItemImg: url, uploadState: false}); this.saveItem()})});
				})			
			},'image/jpeg');			
		}	
	}
	
	UID = 1;	
	getUniqueID(type){
		if(type === 'cat'){var UIDArr = this.props.observableStore.menus[this.state.currMenu].menu}
		if(type === 'item'){var UIDArr = this.props.observableStore.menus[this.state.currMenu].items}
		for (var i = 0; i < UIDArr.length; i++) {
			if(this.UID === UIDArr[i].id){
				this.UID = this.UID + 1;
				return this.getUniqueID(type);
			}
		}
		var newUID = this.UID;
		this.UID = 1;
		return newUID;
	}
	
	onSortEnd = ({oldIndex, newIndex}) => {
		this.props.observableStore.menus[this.state.currMenu].items = arrayMove(this.props.observableStore.menus[this.state.currMenu].items, oldIndex, newIndex);
		this.saveCurrMenu();
	}
	
	loadImg = (event) => {
		var file = event.target.files[0];	
		if(file !== undefined){
			if(file.type === 'image/png' || file.type === 'image/jpeg' ){			
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => {this.setState({imageSrc: reader.result})};		
			}
			else{alert('Bestand moet een jpeg of png afbeelding zijn.')}	  
		}
	}	
	uploadImage = '';
	
	onCropChange = (crop) => {
		this.setState({ crop });
	}

	onZoomChange = (zoom) => {
		this.setState({ zoom });
	}
	
	selectMenu = (val) => {
		if(val === 'new'){
			this.toggleNewMenu();
		}
		else if(val === 'import'){
			this.upload.click();
		}
		else{
			this.setState({currMenu: val});
		}
	}
	
	newOptie = () => {
		this.setState({editOptieIndex: 'new', newOptieText: ''});
		this.toggleOptieModal();
	}
	
	editOptie = (index) => {
		this.setState({editOptieIndex: index, newOptieText: this.state.newItemOpties[index]});
		this.toggleOptieModal();
	}

	delOptie = (ind) => {
		var newItemOpties = this.state.newItemOpties;
		newItemOpties.splice(ind, 1);
		this.setState({newItemOpties: newItemOpties});
	}

	saveOptie = () => {
		if(this.state.editOptieIndex === 'new'){
			var newItemOpties = this.state.newItemOpties;
			newItemOpties.push(this.state.newOptieText.toLowerCase());
			this.setState({newItemOpties: newItemOpties});
		}
		else{this.state.newItemOpties[this.state.editOptieIndex] = this.state.newOptieText.toLowerCase()}
		this.toggleOptieModal();
	}

	onOptieSortEnd = ({oldIndex, newIndex}) => {
		var newItemOpties = this.state.newItemOpties;
		newItemOpties = arrayMove(newItemOpties, oldIndex, newIndex);	
		this.setState({newItemOpties: newItemOpties});	
	}

	reqFields(type){
		var reqFields = [];
		if(type === 'item'){
			if(!this.state.newItemName || this.state.newItemName === ''){reqFields.push('newItemName')}
			if(!this.state.newItemInfo || this.state.newItemInfo === ''){reqFields.push('newItemInfo')}
			if(this.state.imageSrc === '' && this.state.newItemImg === ''){reqFields.push('newItemImg')}
		}
		if(reqFields.length > 0){
			this.setState({reqFields: reqFields});
			return false;
		}
		return true;
	}
	
	render() {
		return (
			<div style={{paddingLeft: 10, paddingRight: 10, paddingBottom: 100}}>
				<Row style={{marginTop: 20}}>
					<Col lg={12}>
						<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Assortiment</h2>
					</Col>
				</Row>
				
				<Row style={{marginTop: 42, paddingLeft: 15, paddingRight: 15, }}>
					<Col lg={2} style={{paddingLeft: 0, paddingRight: 0}}>
						<Input type="select" className='minimal' style={{width: '100%', height: 40, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)'}} onChange={e => {this.selectMenu(e.target.value)}}>
							{this.props.observableStore.menus.map((menu, index) => (	
								<option value={index} selected={this.state.currMenu === index}>{menu.label.charAt(0).toUpperCase() + menu.label.slice(1)}</option>
							))}
							<option disabled>-----------------------</option>
							<option value={'new'}>Nieuw menu</option>
						</Input>
					</Col>
					<Col className="rightBtnCol">
						<div className="addBtnTop" style={{marginRight: 15, width: 175, marginBottom: 0}} onClick={() => this.newItem()}>
							Item toevoegen								
						</div>
						<div className="addBtnTop" style={{marginRight: 15, width: 150, marginBottom: 0}} onClick={() => this.toggleEditMenu()}>
							Wijzigen								
						</div>
						<div className="addBtnTop" style={{marginRight: 15, width: 150, marginBottom: 0}} onClick={() => this.importMenu()}>
							Importeren							
						</div>
						<div className="addBtnTop" style={{width: 150, marginBottom: 0, background: '#d12a5e', color: '#fff'}} onClick={() => this.togglePublishModal()}>
							Publiceren									
						</div>
					</Col>
				</Row>
				
				<Row style={{marginTop: 30, paddingLeft: 15, paddingRight: 15}}>					
					<Col className="graph-display" style={{padding: 40, paddingTop: 30}}>
						{this.props.observableStore.menus[this.state.currMenu] && this.props.observableStore.menus[this.state.currMenu].items.length > 0 ?
							<div style={{minHeight: 250}}>
								<SortableList items={this.props.observableStore.menus[this.state.currMenu].items} editItem={this.editItem} onSortEnd={this.onSortEnd} useDragHandle={true}/>
							</div>
						:
							<div className="emptyContainer"><span>Er zijn nog geen Items toegevoegd.</span></div>
						}
					</Col>
				</Row>
				
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} centered>
					<ModalHeader toggle={this.toggle} style={{marginBottom: 10}}>
						<a onClick={() => this.setState({productTab: 'product'})} style={{cursor: 'pointer', fontSize: 15, color: this.state.productTab === 'product' ? '#000' : 'rgba(0,0,0,0.5)'}}>Product</a> 
						<a onClick={() => this.setState({productTab: 'opties'})} style={{cursor: 'pointer', marginLeft: 25, fontSize: 15, color: this.state.productTab === 'opties' ? '#000' : 'rgba(0,0,0,0.5)'}}>Opties</a> 
						<div style={{marginLeft: this.state.productTab === 'product' ? -6 : 72, width: 70, height: 1, backgroundColor: '#d12a5e', position: 'absolute', marginTop: 16}}></div>
					</ModalHeader>
					<Row>
						<Col lg={12}>
							{this.state.productTab === 'product' ?
							<ModalBody>
								<Row>
									<Col sm={6}>
										<FormGroup>
											<Label htmlFor="example-email">Naam</Label>
											<Input type="text" style={{borderColor: this.state.reqFields.includes('newItemName') ? 'red' : undefined}} id="example-email" name="name" placeholder="Bijv: Bier" value={this.state.newItemName} onChange={e => this.setState({newItemName: e.target.value.replace('/', '')})}/>
										</FormGroup>
									</Col>
									<Col sm={6}>
										<FormGroup>
											<Label htmlFor="example-email">Status</Label>
											<Input type="select" className='minimal' onChange={e => this.setState({newItemStatus: e.target.value})}>
												<option value="beschikbaar" selected={this.state.newItemStatus === "beschikbaar" ? true : false}>Op voorraad</option>
												<option value="uitverkocht" selected={this.state.newItemStatus === "uitverkocht" ? true : false}>Uitverkocht</option>
												<option value="verborgen" selected={this.state.newItemStatus === "verborgen" ? true : false}>Verborgen</option>
											</Input>
										</FormGroup>
									</Col>
								</Row>
								<Row style={{marginTop: 5}}>
									<Col sm={12}>
										<FormGroup>
											<Label htmlFor="example-email">Omschrijving</Label>
											<Input type="textarea" style={{borderColor: this.state.reqFields.includes('newItemInfo') ? 'red' : undefined}} id="inputArea" name="name" placeholder="Bijv: Beker 0.25ml" value={this.state.newItemInfo} onChange={e => this.setState({newItemInfo: e.target.value})}/>
										</FormGroup>	
									</Col>
								</Row>
								<Row style={{marginTop: 5}}>
									<Col sm={4} style={{paddingRight: 10}}>
										<FormGroup>
											<Label htmlFor="example-email">Prijs</Label>
											<Input type="select" className='minimal' onChange={e => this.setState({newItemPrice: parseFloat(e.target.value)})}>
												<option value="0.5" selected={this.state.newItemPrice === 0.5 ? true : false}>0.5 Token</option>
												<option value="1" selected={this.state.newItemPrice === 1 ? true : false}>1 Token</option>
												<option value="1.5" selected={this.state.newItemPrice === 1.5 ? true : false}>1.5 Token</option>
												<option value="2" selected={this.state.newItemPrice === 2 ? true : false}>2 Tokens</option>
												<option value="2.5" selected={this.state.newItemPrice === 2.5 ? true : false}>2.5 Tokens</option>
												<option value="3" selected={this.state.newItemPrice === 3 ? true : false}>3 Tokens</option>
												<option value="3.5" selected={this.state.newItemPrice === 3.5 ? true : false}>3.5 Tokens</option>
												<option value="4" selected={this.state.newItemPrice === 4 ? true : false}>4 Tokens</option>
												<option value="4.5" selected={this.state.newItemPrice === 4.5 ? true : false}>4.5 Tokens</option>
												<option value="5" selected={this.state.newItemPrice === 5 ? true : false}>5 Tokens</option>
												<option value="5.5" selected={this.state.newItemPrice === 5.5 ? true : false}>5.5 Tokens</option>
												<option value="6" selected={this.state.newItemPrice === 6 ? true : false}>6 Tokens</option>
												<option value="7" selected={this.state.newItemPrice === 7 ? true : false}>7 Tokens</option>
												<option value="8" selected={this.state.newItemPrice === 8 ? true : false}>8 Tokens</option>
												<option value="9" selected={this.state.newItemPrice === 9 ? true : false}>9 Tokens</option>
												<option value="10" selected={this.state.newItemPrice === 10 ? true : false}>10 Tokens</option>
												<option value="11" selected={this.state.newItemPrice === 11 ? true : false}>11 Tokens</option>
												<option value="12" selected={this.state.newItemPrice === 12 ? true : false}>12 Tokens</option>
												<option value="13" selected={this.state.newItemPrice === 13 ? true : false}>13 Tokens</option>
												<option value="14" selected={this.state.newItemPrice === 14 ? true : false}>14 Tokens</option>
												<option value="15" selected={this.state.newItemPrice === 15 ? true : false}>15 Tokens</option>
												<option value="16" selected={this.state.newItemPrice === 16 ? true : false}>16 Tokens</option>
												<option value="17" selected={this.state.newItemPrice === 17 ? true : false}>17 Tokens</option>
												<option value="18" selected={this.state.newItemPrice === 18 ? true : false}>18 Tokens</option>
												<option value="19" selected={this.state.newItemPrice === 19 ? true : false}>19 Tokens</option>
												<option value="20" selected={this.state.newItemPrice === 20 ? true : false}>20 Tokens</option>
												<option value="25" selected={this.state.newItemPrice === 25 ? true : false}>25 Tokens</option>
												<option value="30" selected={this.state.newItemPrice === 30 ? true : false}>30 Tokens</option>
												<option value="35" selected={this.state.newItemPrice === 35 ? true : false}>35 Tokens</option>
												<option value="40" selected={this.state.newItemPrice === 40 ? true : false}>40 Tokens</option>
											</Input>
										</FormGroup>
									</Col>
									<Col sm={3} style={{paddingRight: 10, paddingLeft: 10}}>
										<FormGroup>
											<Label htmlFor="example-email">BTW</Label>
											<Input type="select" className='minimal' onChange={e => this.setState({newItemBTW: parseFloat(e.target.value)})}>
												<option value="0.09" selected={this.state.newItemBTW === 0.09 ? true : false}>9%</option>
												<option value="0.13" selected={this.state.newItemBTW === 0.13 ? true : false}>13%</option>
												<option value="0.21" selected={this.state.newItemBTW === 0.21 ? true : false}>21%</option>
												<option value="0" selected={this.state.newItemBTW === 0 ? true : false}>0%</option>
											</Input>
										</FormGroup>
									</Col>
									<Col sm={5} style={{paddingLeft: 10}}>
										<FormGroup>
											<Label htmlFor="example-email">Serveren</Label>
											<Input type="select" className='minimal' style={{width: '100%', marginBottom: 20}} onChange={e => this.setState({newItemServe: e.target.value})}>
												<option value="direct" selected={this.state.newItemServe === 'direct'}>Direct serveren</option>
												<option value="bereiden" selected={this.state.newItemServe === 'bereiden'}>Bereiden</option>
											</Input>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col sm={7}>
										<Row onClick={() => this.setState({newItemCup: !this.state.newItemCup})} style={{cursor: 'pointer', marginTop: 10, marginBottom: 7, marginLeft: 1}}>
											{this.state.newItemCup ?
											<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
												<i class="fa fa-check" style={{color: '#4cc15f', paddingLeft: 1, marginTop: 2, position: 'absolute'}}></i>
											</div>
											:
											<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
											}
											<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Item komt in herbruikbare beker</p>
										</Row>
									</Col>
									<Col sm={5}>
										<Row onClick={() => this.setState({newItemEighteen: !this.state.newItemEighteen})} style={{cursor: 'pointer', marginTop: 10, marginBottom: 7, marginLeft: 1}}>
											{this.state.newItemEighteen ?
											<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
												<i class="fa fa-check" style={{color: '#4cc15f', paddingLeft: 1, marginTop: 2, position: 'absolute'}}></i>
											</div>
											:
											<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
											}
											<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Alleen voor 18+</p>
										</Row>
									</Col>
								</Row>
								<div style={{marginTop: 10}}>
									<Row>
										<Col sm={6}>
											<div style={{display: 'flex', justifyContent: 'center', width: '100%', paddingTop: 10, paddingBottom: 10, backgroundColor: 'rgba(0,0,0,0.05)', borderRadius: 7, borderWidth: 1, borderStyle: 'solid', borderColor: this.state.reqFields.includes('newItemImg') ? 'red' : 'rgba(0,0,0,0)'}}>
												<img class="menu_img" src={this.state.newItemImg === '' ? drinkPlaceholder : this.state.newItemImg} height="94"/>
											</div>
										</Col>
										<Col sm={6}>
											<div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
												<div className="addBtnLst" style={{width: 100, fontSize: 12, paddingTop: 9, height: 34, marginBottom: 5}} onClick={() => this.imgUpload.click()}>Uploaden</div>
												<Input innerRef={input => this.imgUpload = input} style={{display: 'none'}} type="file" className="custom-file-input" id="customFile1" onChange={e => this.loadImg(e)} />
												<label className="col-form-label" style={{fontSize: 11}}>Upload een jpg of png afbeelding</label>
											</div>
										</Col>
									</Row>
									{this.state.imageSrc === '' ?
										<div style={{marginTop: 20, marginBottom: 10}}>
										{this.state.iconsLoaded ?
											<div>
												<div style={{maxHeight: 195, overflowY: 'scroll',}}>
													{this.state.iconList.map((image, i) => {
														if(this.state.searchInput.length < 2 || this.state.searchInput.length >= 2 && image.toLowerCase().includes(this.state.searchInput) || this.state.searchInput.length >= 2 && image.toLowerCase().includes(this.state.searchInput)){
														return <div style={{float: 'left', padding: 4, cursor: 'pointer'}} onClick={() => this.setState({newItemImg: image})}>
															<img src={image} height="43.9"/>
														</div>	
														}									
													})}
												</div>	
												<Input type="text" id="example-email" style={{marginTop: 10}} name="name" placeholder="Zoek op afbeeldingen" value={this.state.searchInput} onChange={e => this.setState({searchInput: e.target.value.toLowerCase()})}/>
											</div>
											:
											<div style={{display: 'flex', width: '100%', height: 195, justifyContent: 'center', alignItems: 'center'}}>
												<div class="loading-spinner" style={{height: 50, width: 50}}></div>
											</div>
											}
										</div>
									:
									<div style={{marginTop: 20}}>
										{this.state.uploadState ?
											<div style={{position: 'relative', width: '100%', height: 275, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
												<div class="loading-spinner" style={{width: 75, height: 75, borderWidth: 6}}></div>
											</div>
											:
											<div>
												<div style={{position: 'relative', width: '100%', height: 275, overflow: 'hidden'}}>
													<Cropper
														image={this.state.imageSrc}
														crop={this.state.crop}
														zoom={this.state.zoom}
														aspect={15 / 8}
														cropSize={{width: 330, height: 176}}
														onCropChange={this.onCropChange}
														onCropComplete={(croppedArea, croppedAreaPixels) => {this.setState({pixelCrop: croppedAreaPixels})}}
														restrictPosition={false}
														showGrid={false}
													/>
												</div>										
												<div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, overflow: 'hidden'}}>					
													<div style={{width: '100%'}}>
														<RangeStepInput style={{width: '100%'}} value={this.state.zoom} min={0} max={3} step={0.1} onChange={e => this.setState({zoom: e.target.value})}/>
													</div>
												</div>	
											</div>
										}
									</div>
									}
								</div>
							</ModalBody>
							:
							<ModalBody>
								<p style={{fontSize: 16, marginTop: 2, color: '#4a4a4a', fontWeight: 900}}>Extra opties</p>
								<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Je kunt extra opties toevoegen aan producten zoals bijvoorbeeld sauzen bij friet of frisdrank bij vodka.</p>
								<SortableOptieList helperClass='is-moving' items={this.state.newItemOpties} onSortEnd={this.onOptieSortEnd} editOptie={this.editOptie} delOptie={this.delOptie} useDragHandle={true} />
								<div onClick={() => this.newOptie()} style={{display: 'flex', justifyContent: 'center'}}><div className='addBtnLst' style={{marginTop: 25, marginBottom: 30, fontSize: 13, width: 160}}>Optie toevoegen</div></div>
								<Modal isOpen={this.state.optieModal} toggle={() => this.toggleOptieModal} className={this.props.className} centered style={{maxWidth: 400}} >
									<ModalHeader toggle={this.toggleOptieModal}>Optie {this.state.editOptieIndex === 'new' ? 'Toevoegen' : 'Aanpassen'}</ModalHeader>
									<ModalBody>
										<FormGroup>
											<Label htmlFor="example-email">Optie tekst</Label>
											<Input type="text" id="example-email" name="name" placeholder="Bijv: met cola" value={this.state.newOptieText} onChange={e => this.setState({newOptieText: e.target.value.replace('/', '')})}/>
										</FormGroup>
									</ModalBody>
									<ModalFooter>
										{this.state.editOptieIndex !== 'new' ?
										<Button className="menuDelButton" onClick={this.delOptie}>
										verwijderen
										</Button>
										:null}
										<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.saveOptie()}>
											{this.state.editOptieIndex === 'new' ? 'Toevoegen' : 'Aanpassen'}
										</Button>
										<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleOptieModal}>
											Annuleren
										</Button>
									</ModalFooter>
								</Modal>
							</ModalBody>
							}
						</Col>
					</Row>
					<ModalFooter style={{justifyContent: 'space-between'}}>	
						<div>
							{this.state.editItemIndex !== 'new' && this.state.productTab === 'product' ?
							<Button className="menuDelButton" onClick={this.toggleDelModal}>
							  verwijderen
							</Button>
							:null}	
						</div>
						<div style={{display: 'flex', marginLeft: 0}}>
							<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.checkImg('item')}>
								{this.state.editItemIndex === 'new' ? 'Toevoegen' : 'Aanpassen'}
							</Button>
							<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggle}>
								Annuleren
							</Button>
						</div>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.newMenuModal} toggle={this.toggleNewMenu} className={this.props.className} centered >
					<ModalHeader toggle={this.toggleNewMenu}>Nieuw menu</ModalHeader>
					<ModalBody>
						<FormGroup>
							<Label htmlFor="example-email">Label</Label>
							<Input type="text" id="example-email" name="name" placeholder="Bijv: Festival menu" value={this.state.newMenuLabel} onChange={e => this.setState({newMenuLabel: e.target.value})}/>
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.addMenu}>
						  opslaan
						</Button>
						<Button className="cancel_btn" onClick={this.toggleNewMenu}>
						  Annuleren
						</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.editMenuModal} toggle={this.toggleEditMenu} className={this.props.className} centered >
					<ModalHeader toggle={this.toggleEditMenu}>Menu wijzigen</ModalHeader>
					<ModalBody>
						<FormGroup>
							<Label htmlFor="example-email">Label</Label>
							<Input type="text" id="example-email" name="name" placeholder="Bijv: Festival menu" value={this.state.newMenuLabel} onChange={e => this.setState({newMenuLabel: e.target.value})}/>
						</FormGroup>
					</ModalBody>
					<ModalFooter style={{justifyContent: 'space-between'}}>
						<div>
							<Button style={{backgroundColor: 'rgb(229, 57, 53)', border: 0}} onClick={this.delMenu}>
							  verwijderen
							</Button>
						</div>
						<div style={{display: 'flex'}}>
							<Button style={{backgroundColor: '#d12a5e', border: 0, marginRight: 10}} onClick={this.editMenu}>
							  opslaan
							</Button>
							<Button className="cancel_btn" onClick={this.toggleEditMenu}>
							  Annuleren
							</Button>
						</div>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.infoModal} toggle={() => this.toggleInfoModal('')} className={this.props.className} centered >
					<ModalHeader toggle={() => this.toggleInfoModal('')}>Informatie</ModalHeader>
					<ModalBody>
						<p style={{marginTop: 10}}>{this.state.infoModalTxt}</p>
					</ModalBody>
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.toggleInfoModal('')}>
						  Sluit venster
						</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.publishModal} toggle={() => this.togglePublishModal('')} className={this.props.className} centered >
					<ModalHeader toggle={() => this.togglePublishModal('')}>Menu publiceren</ModalHeader>
					<ModalBody>
						{this.state.publishState === 0 ?
							<p style={{marginTop: 10}}>Wanneer je op "Publiceren" klikt worden de items tegevoegd aan de toegewezen categorie van het evenement menu. Het menu wordt{this.props.observableStore.directPublish ? ' ' : ' niet '}direct gepubliceerd naar de app</p>
						:null}
						{this.state.publishState === 1 ?
						<div>
							<center>
								<div class="loading-spinner" style={{height: 75, width: 75, marginTop: 50, marginBottom: 50}}></div>
								<p style={{marginTop: 10}}>Bezig met publiceren</p>
							</center>
						</div>
						:null}
						{this.state.publishState === 2 ?
							<p style={{marginTop: 10}}>Het menu is gepubliceerd</p>
						:null}
						{this.state.publishState === 3 ?
							<p style={{marginTop: 10}}>Er is iets mis gegaan</p>
						:null}
					</ModalBody>
					<ModalFooter>
						{this.state.publishState === 0 ?
						<Button style={{backgroundColor: '#d12a5e', border: 0, marginRight: 10}} onClick={() => this.publishMenu()}>
						  Publiceren
						</Button>
						:null}
						<Button style={{border: 0}} onClick={() => this.togglePublishModal('')}>
						  Sluit venster
						</Button>
					</ModalFooter>
				</Modal>	
				<Modal isOpen={this.state.delModal} toggle={() => this.toggleDelModal} className={this.props.className} centered style={{maxWidth: 400}} >
					<ModalHeader toggle={this.toggleDelModal}>Item verwijderen</ModalHeader>
					<ModalBody>
						<div style={{paddingTop: 20, paddingBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<img src={Warning} style={{width: 50, marginBottom: 15}} />
							<p style={{marginTop: 10, textAlign: 'center'}}><b>Let op!</b> Het is afgeraden om items tijdens een evenement te verwijderen. Het is geadviseerd om in dit geval de status op verborgen of uitverkocht te zetten.</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button className="menuDelButton" style={{backgroundColor: '#d12a5e'}} onClick={this.delItem}>
						  verwijderen
						</Button>
						<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleDelModal}>
							Annuleren
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default Menu;

const SortableList = SortableContainer(({items, editItem}) => {
	return (
		<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
			<tbody>
				{items.map((value, index) => (<SortableItem key={`item-${value}`} index={index} ind={index} editItem={editItem} value={value} />))}
			</tbody>
		</Table>
	);
});

const SortableItem = SortableElement(({value, ind, editItem}) => 
	<tr style={{opacity: value.status === "verborgen" ? 0.5 : 1, background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
		<td style={{padding: 8, paddingRight: 0, borderWidth: 0}}><div style={{width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}><img className="menu_img" style={{width: 90}} src={value.img} alt={value.name} /></div></td>
		<td style={{width: window.innerWidth, borderWidth: 0}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.name}</h6></td>
		<td style={{width: window.innerWidth, borderWidth: 0}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.info ? value.info : 'geen omschrijving'}</h6></td>
		<td style={{width: '15%', borderWidth: 0}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.price} {value.price === 1 ? 'token' : 'tokens'}</h6></td>
		<td style={{width: '15%', borderWidth: 0}}>
		{value.status === "uitverkocht" ?
			<h6 style={{width: 125, fontWeight: 500, background: '#e53935', textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 8}}>Uitverkocht</h6>
		:
			<h6 style={{width: 125, fontWeight: 500, background: '#43A047', textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 8}}>op voorraad</h6>
		}
		</td>
		<td style={{fontSize: 18, minWidth: 90, padding: 0, textAlign: 'right', borderWidth: 0}}>
			<i class="fa fa-edit hightlightIcon2" onClick={() => editItem(ind, value.name, value.info, value.img, value.price, value.btw, value.serve, value.status, value.reqEighteen, value.opties, value.cup)} style={{cursor:'pointer'}}></i>
			<DragHandle />
		</td>
	</tr>
);

const SortableOptieList = SortableContainer(({items, editOptie, delOptie}) => {
	return (
		<Table className="v-middle" style={{marginBottom: 0, borderWidth: 0}}>
			<tbody>
				{items.map((value, index) => (<SortableOptie key={index} index={index} ind={index} value={value} editOptie={editOptie} delOptie={delOptie} />))}
			</tbody>
		</Table>
	);
});

const SortableOptie = SortableElement(({value, ind, editOptie, delOptie}) => 
	<div className="menuoptienav" style={{background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}} >
		<div >
			<span>{value}</span>
		</div>
		<div style={{marginLeft: 10, display: 'flex', cursor:'pointer'}}>
			<i class="fa fa-edit hightlightIcon2" onClick={() => editOptie(ind)}></i>
			<i class="fa fa-trash hightlightIcon2" style={{marginLeft: 12}} onClick={() => delOptie(ind)}></i>
			<DragHandle />
		</div>
	</div>
);

const DragHandle = sortableHandle(() => <i class="fa-regular fa-arrows-alt hightlightIcon2" style={{marginLeft: '15%', marginRight: '10%', cursor:'pointer'}}></i>);